var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"titulo-e-divider"},[_c('h1',[_vm._v("Titular")]),_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3","offset-lg":"4","offset-xl":"6"}},[(_vm.beneficio.length === 0)?_c('v-btn',{staticClass:"botao-principal w-100",on:{"click":function($event){return _vm.abrirModalSolicitarVale()}}},[_c('v-icon',{staticClass:"icone-solicitacoes"},[_vm._v("fas fa-plus")]),_vm._v("Solicitar Vale-Transporte ")],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('v-btn',{staticClass:"botao-principal w-100",on:{"click":function($event){_vm.showMinhasSolicitacoes = true}}},[_c('v-img',{staticClass:"icone-solicitacoes",attrs:{"src":require("@/assets/images/icone/espaco-colaborador/icone_document.svg")}}),_vm._v(" Minhas Solicitações ")],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.beneficio,"hide-default-footer":true,"no-data-text":"Você não possui vale transporte cadastrado!"},scopedSlots:_vm._u([_vm._l((_vm.header.filter((header) =>
            header.hasOwnProperty('formatter')
          )),function(header){return {key:`item.${header.value}`,fn:function({ value }){return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:`item.requerimento`,fn:function(data){return [_c('div',{staticClass:"acoes-tabela"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"icone-acao",on:{"click":function($event){return _vm.abrirModalTrocarVale(data.item)}}},'v-icon',attrs,false),on),[_vm._v(" fas fa-pencil-alt")])]}}],null,true)},[_c('span',[_vm._v("Trocar Vale-Transporte")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"icone-acao",on:{"click":function($event){return _vm.abrirModalCancelarBeneficio(data.item)}}},'v-icon',attrs,false),on),[_vm._v(" fas fa-times")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])])],1)]}}],null,true)})],1)],1),_c('alerta',{attrs:{"exibir":_vm.modal.exibir,"titulo":_vm.modal.titulo,"mensagem":_vm.modal.mensagem},on:{"fecharModal":_vm.fecharModal}}),_c('ModalMinhasSolicitacoes',{model:{value:(_vm.showMinhasSolicitacoes),callback:function ($$v) {_vm.showMinhasSolicitacoes=$$v},expression:"showMinhasSolicitacoes"}}),_c('ModalCancelarBeneficio',{attrs:{"beneficio":_vm.modalCancelar.beneficio,"tituloModal":_vm.modalCancelar.titulo,"tipoMudanca":_vm.modalCancelar.tipoMudanca},on:{"sucesso":function($event){return _vm.mensagemSucesso(
        'Pedido realizado com sucesso. Aguarde o atendimento da solicitação.'
      )}},model:{value:(_vm.modalCancelar.show),callback:function ($$v) {_vm.$set(_vm.modalCancelar, "show", $$v)},expression:"modalCancelar.show"}}),_c('ModalSolicitarAlterarTransporte',{attrs:{"titulo":_vm.modalSolicitarTransporte.titulo,"beneficio":_vm.modalSolicitarTransporte.beneficio,"isTroca":_vm.modalSolicitarTransporte.isTroca},on:{"sucesso":function($event){return _vm.mensagemSucesso(
        'Pedido realizado com sucesso. Aguarde o atendimento da solicitação.'
      )}},model:{value:(_vm.modalSolicitarTransporte.show),callback:function ($$v) {_vm.$set(_vm.modalSolicitarTransporte, "show", $$v)},expression:"modalSolicitarTransporte.show"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }