<template>
  <v-container>
    <!-- Título -->
    <v-row>
      <v-col class="titulo-e-divider">
        <h1>Titular</h1>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <!-- Botões -->
    <v-row>
      <v-col cols="12" md="6" lg="4" xl="3" offset-lg="4" offset-xl="6">
        <v-btn
          v-if="beneficio.length === 0"
          @click="abrirModalSolicitarVale()"
          class="botao-principal w-100"
        >
          <v-icon class="icone-solicitacoes">fas fa-plus</v-icon>Solicitar
          Vale-Transporte
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-btn
          @click="showMinhasSolicitacoes = true"
          class="botao-principal w-100"
        >
          <v-img
            class="icone-solicitacoes"
            src="@/assets/images/icone/espaco-colaborador/icone_document.svg"
          ></v-img>
          Minhas Solicitações
        </v-btn>
      </v-col>
    </v-row>
    <!-- Tabela -->
    <v-row>
      <v-col>
        <v-data-table
          :headers="header"
          :items="beneficio"
          :hide-default-footer="true"
          no-data-text="Você não possui vale transporte cadastrado!"
        >
          <template
            v-for="header in header.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>

          <template v-slot:[`item.requerimento`]="data">
            <div class="acoes-tabela">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="abrirModalTrocarVale(data.item)"
                    v-bind="attrs"
                    v-on="on"
                    class="icone-acao"
                  >
                    fas fa-pencil-alt</v-icon
                  >
                </template>
                <span>Trocar Vale-Transporte</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="abrirModalCancelarBeneficio(data.item)"
                    v-bind="attrs"
                    v-on="on"
                    class="icone-acao"
                  >
                    fas fa-times</v-icon
                  >
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
    />

    <ModalMinhasSolicitacoes v-model="showMinhasSolicitacoes" />

    <ModalCancelarBeneficio
      v-model="modalCancelar.show"
      :beneficio="modalCancelar.beneficio"
      :tituloModal="modalCancelar.titulo"
      :tipoMudanca="modalCancelar.tipoMudanca"
      @sucesso="
        mensagemSucesso(
          'Pedido realizado com sucesso. Aguarde o atendimento da solicitação.'
        )
      "
    />

    <ModalSolicitarAlterarTransporte
      v-model="modalSolicitarTransporte.show"
      :titulo="modalSolicitarTransporte.titulo"
      :beneficio="modalSolicitarTransporte.beneficio"
      :isTroca="modalSolicitarTransporte.isTroca"
      @sucesso="
        mensagemSucesso(
          'Pedido realizado com sucesso. Aguarde o atendimento da solicitação.'
        )
      "
    />
  </v-container>
</template>

<script>
import api from "@/services/Api.js";
import modalMixin from "@/mixins/modalMixin";
import alerta from "@/components/Alerta.vue";
import loader from "@/components/Loader.vue";
import { TipoMudancaEnum } from "@/enums/beneficio/TipoMudancaEnum.js";
import ModalMinhasSolicitacoes from "@/components/EspacoDoColaborador/Beneficios/modais/ModalMinhasSolicitacoes.vue";
import ModalCancelarBeneficio from "@/components/EspacoDoColaborador/Beneficios/modais/ModalCancelarBeneficio.vue";
import ModalSolicitarAlterarTransporte from "@/components/EspacoDoColaborador/Beneficios/modais/ModalSolicitarAlterarTransporte.vue";

export default {
  mixins: [modalMixin],
  components: {
    ModalMinhasSolicitacoes,
    ModalCancelarBeneficio,
    ModalSolicitarAlterarTransporte,
    alerta,
    loader,
  },
  props: {
    header: Array,
    beneficio: Array,
  },
  data() {
    return {
      showMinhasSolicitacoes: false,
      modalCancelar: {
        show: false,
        beneficio: null,
        tipoMudanca: null,
        titulo: "",
      },
      modalSolicitarTransporte: {
        show: false,
        beneficio: null,
        titulo: "",
        isTroca: false,
      },
    };
  },
  methods: {
    async validarPeriodo() {
      const vm = this;
      return new Promise(async (resolve, reject) => {
        var periodo;
        await api
          .get(`beneficios/periodo-transporte`)
          .then(async (response) => await (periodo = response.data))
          .catch(() => reject(vm.mensagemErro("Erro ao buscar período")));
        const diaHoje = await new Date().getDate();
        if (diaHoje >= periodo.diaInicial && diaHoje <= periodo.diaFinal) {
          resolve();
        } else {
          reject(
            vm.mensagem(
              "ATENÇÃO!",
              `Solicitações para vale transporte
                só podem ser realizadas entre os dias ${periodo.diaInicial} e ${periodo.diaFinal}`
            )
          );
        }
      });
    },
    async abrirModalCancelarBeneficio(item) {
      const vm = this;
      await vm
        .validarPeriodo()
        .then(() => {
          vm.modalCancelar = {
            beneficio: item,
            titulo: `Cancelar ${item.descricao}`,
            tipoMudanca: TipoMudancaEnum.CANCELAMENTO,
            show: true,
          };
        })
        .catch(() => console.error("Período não autorizado"));
    },
    async abrirModalTrocarVale(item) {
      const vm = this;
      await vm
        .validarPeriodo()
        .then(() => {
          vm.modalSolicitarTransporte = {
            titulo: `Trocar ${item.descricao}`,
            beneficio: item,
            show: true,
            isTroca: true,
          };
        })
        .catch(() => console.error("Período não autorizado"));
    },
    async abrirModalSolicitarVale() {
      const vm = this;
      await vm
        .validarPeriodo()
        .then(() => {
          vm.modalSolicitarTransporte = {
            titulo: `Solicitar vale transporte`,
            beneficio: null,
            show: true,
            isTroca: false,
          };
        })
        .catch(() => console.error("Período não autorizado"));
    },
  },
};
</script>

<style scoped></style>
