<template>
  <v-container>
    <v-dialog v-model="show" max-width="1200" @click:outside="fecharModal()">
      <v-card>
        <v-card-title>
          <h5>{{ tituloModal }}</h5>
          <v-icon small @click="fecharModal()"> fa-times </v-icon>
        </v-card-title>
        <v-card-text class="fundo-branco">
          <v-container>
            <div class="espacamento">
              <div class="alerta">
                <div>
                  <v-img
                    class="icone-alerta"
                    src="@/assets/images/icone/espaco-colaborador/icone_sinal_de_aviso.svg"
                  ></v-img>
                </div>
                <v-divider
                  inset
                  vertical
                  color="#278b97"
                  justify="center"
                  class="mb-2"
                  :class="$vuetify.breakpoint.xs ? 'd-none' : ''"
                ></v-divider>
                <p class="text-alerta">
                  Os valores dos planos variam por região. Para entender como é
                  oferecido o benefício, valores e opções entre em contato com
                  <a
                    style="text-decoration: none; color: #278b97"
                    href="mailto:departamentopessoal@hepta.com.br?subject=Plano de Saúde"
                    >departamentopessoal@hepta.com.br</a
                  >.
                </p>
              </div>
              <v-row no-gutters class="align-center justify-space-between">
                <h4 class="destaque">
                  <v-icon small>fas fa-caret-right</v-icon> Quais dependentes
                  você deseja adicionar ao plano?
                </h4>
                <v-btn
                  class="destaque-bt rounded-lg novo-btn"
                  color="#278b97"
                  @click="abrirFormAdicionarDependente = true"
                  :disabled="abrirFormAdicionarDependente"
                >
                  <v-icon class="mr-3">fas fa-circle-plus</v-icon>
                  Adicionar novo dependente
                </v-btn>
              </v-row>
              <v-form ref="form" lazy-validation>
                <v-row
                  class="adicionar-dependente"
                  v-if="abrirFormAdicionarDependente"
                >
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="vModel.nome"
                      label="Nome do dependente"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="[(v) => !!v || 'Obrigatório informar o nome']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="vModel.nomeMae"
                      label="Nome da mãe do dependente"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="[
                        (v) => !!v || 'Obrigatório informar o nome da mãe',
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="vModel.cpf"
                      label="CPF"
                      v-mask="'###.###.###-##'"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="[(v) => !!v || 'Obrigatório informar o CPF']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="vModel.dtNascimento"
                      type="date"
                      label="Data de Nascimento"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="[
                        (v) => !!v || 'Obrigatório informar data de nascimento',
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="vModel.sexo"
                      :items="item.sexo"
                      item-text="descricao"
                      item-value="id"
                      :menu-props="{ bottom: true, offsetY: true }"
                      label="Sexo"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="[
                        (v) => v !== null || 'Obrigatório informar o sexo',
                      ]"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="vModel.grauParentesco"
                      item-text="descricao"
                      item-value="id"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :items="item.grauParentesco"
                      label="Grau de Parentesco"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="[
                        (v) =>
                          !!v || 'Obrigatório informar o grau de parentesco',
                      ]"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      class="botao-cancelar mr-2 largura-btn"
                      @click="fecharFormNovoDependente()"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      class="botao-salvar mr-2 largura-btn"
                      @click="salvarDependente()"
                      >Adicionar dependente</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </div>
            <v-data-table
              :headers="headerDependente"
              :items="item.dependentes"
              no-data-text="Nenhum dependente "
              hide-default-footer
              class="mt-10"
            >
              <template
                v-for="header in headerDependente.filter((header) =>
                  header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-checkbox
                    v-model="item.selected"
                    @change="selecionarDependente(item)"
                  ></v-checkbox>
                </v-row>
              </template>
            </v-data-table>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions class="justify-center pt-10 pb-4">
            <v-btn class="botao-sair" @click="fecharModal()">Sair</v-btn>
            <v-btn
              class="botao-principal mr-2 largura-btn"
              @click="Solicitar()"
              :disabled="!vModel.dependentes.length > 0"
              >Solicitar</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <loader :loader-primario="loader" />
    <alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
    />
  </v-container>
</template>
<script>
import Vue from "vue";
import api from "@/services/Api.js";
import loader from "@/components/Loader.vue";
import alerta from "@/components/Alerta.vue";
import { montarListaSexo } from "@/enums/sexoEnum.js";
import { montarListaGrauParentesco } from "@/enums/grauParentescoEnum.js";
import { colocarMascaraCPF } from "@/utils/CPFUtils.js";
import { TipoMudancaEnum } from "@/enums/beneficio/TipoMudancaEnum.js";
import modalMixin from "@/mixins/modalMixin";
import VueMask from "v-mask";
Vue.use(VueMask);
export default {
  components: {
    loader,
    alerta,
  },
  mixins: [modalMixin],
  props: {
    value: Boolean,
    tituloModal: {
      type: String,
      default: "modal adicionar dependente",
    },
    dependentes: {
      type: Array,
    },
    beneficio: {
      type: Object,
    },
  },
  data() {
    return {
      abrirFormAdicionarDependente: false,
      paths: {
        dependente: "beneficios/dependente",
        pedido: "beneficios/pedido/plano",
      },
      item: {
        sexo: [],
        grauParentesco: [],
        dependentes: [],
      },
      vModel: {
        cpf: null,
        nome: null,
        nomeMae: null,
        grauParentesco: null,
        dtNascimento: null,
        sexo: null,
        dependentes: [],
      },
      headerDependente: [
        {
          text: "Nome",
          value: "nome",
          width: "30%",
          formatter: (x) => (x ? x.toUpperCase() : "-"),
        },
        {
          text: "CPF",
          value: "cpf",
          width: "25%",
          formatter: (x) => (x ? colocarMascaraCPF(x) : "-"),
        },
        {
          text: "Parentesco",
          value: "grauParentesco.nome",
          width: "25%",
          formatter: (x) => (x ? x.toUpperCase() : "-"),
        },
        {
          text: "Selecionar",
          value: "action",
          width: "20%",
          align: "center",
        },
      ],
      loader: false,
    };
  },
  methods: {
    async fecharFormNovoDependente() {
      const vm = this;
      await vm.$refs["form"].reset();
      vm.abrirFormAdicionarDependente = false;
    },
    async fecharModal() {
      const vm = this;
      await vm.$refs["form"].reset();
      await Object.assign(this.$data, this.$options.data());
      vm.show = false;
    },
    async salvarDependente() {
      const vm = this;
      if (!vm.$refs["form"].validate()) return;
      vm.loader = true;
      var dto;
      dto = vm.montarDTODependente();
      api
        .post(vm.paths.dependente, dto)
        .then(async (res) => {
          await vm.buscarDependentes();
          await vm.$refs["form"].reset();
          vm.abrirFormAdicionarDependente = false;
        })
        .catch((e) => vm.mensagemErro(e.message))
        .finally(() => (vm.loader = false));
    },
    montarDTODependente() {
      const vm = this;
      var dto = {
        nome: vm.vModel.nome,
        cpf: vm.vModel.cpf,
        grauParentesco: {
          id: vm.vModel.grauParentesco,
        },
        dtNascimento: vm.vModel.dtNascimento,
        sexo: vm.vModel.sexo,
        nomeMae: vm.vModel.nomeMae,
      };
      return dto;
    },
    selecionarDependente(item) {
      const vm = this;
      vm.vModel.dependentes.some((d) => d.id === item.id)
        ? vm.vModel.dependentes.splice(
            vm.vModel.dependentes.findIndex((i) => i.id === item.id),
            1
          )
        : vm.vModel.dependentes.push(item);
    },
    async buscarDependentes() {
      const vm = this;
      vm.loader = true;
      await api
        .get(vm.paths.dependente)
        .then(async (res) => {
          await (vm.item.dependentes = res.data
            .filter((d) => !vm.dependentes.some((s) => s.id === d.id))
            .map((x) => {
              return {
                ...x,
                selected: vm.vModel.dependentes.some((d) => d.id === x.id)
                  ? true
                  : false,
              };
            }));
        })
        .catch((e) => vm.mensagemErro(e.message))
        .finally(() => (vm.loader = false));
    },
    Solicitar() {
      const vm = this;
      vm.loader = true;
      api
        .post(vm.paths.pedido, vm.montarDTOPedido())
        .then(async (res) => {
          await vm.$emit("sucesso", res.data);
          vm.fecharModal();
        })
        .catch(() => vm.mensagemErro("Erro ao fazer pedido."))
        .finally(() => (vm.loader = false));
    },
    montarDTOPedido() {
      const vm = this;
      var dto = {
        beneficio: {
          id: vm.beneficio.id,
        },
        tipoMudanca: {
          id: TipoMudancaEnum.ADICIONAR_DEPENDENTE,
        },
        dependentes: vm.vModel.dependentes,
      };
      return dto;
    },
  },
  computed: {
    show: {
      get() {
        if (this.value == true) this.buscarDependentes();
        return this.value;
      },
      set(value) {
        const vm = this;
        this.$emit("input", value);
      },
    },
  },
  async mounted() {
    const vm = this;
    await (vm.item = {
      sexo: montarListaSexo(),
      grauParentesco: montarListaGrauParentesco(),
    });
  },
};
</script>
<style scoped>
.adicionar-dependente {
  border: 2px solid #278b97;
  padding: 30px 20px 40px 20px;
  margin-top: 15px;
}

.divider-tela-inicial {
  margin: 10px 5px 30px 5px;
  opacity: 1;
  justify-items: center;
}

.divider-tela {
  margin: 15px 5px 20px 5px;
  opacity: 1;
  justify-items: center;
}

.theme--light .v-list {
  background-color: #f7f7f7 !important;
}

.titulo {
  font: normal normal bold 18px/22px Montserrat;
  letter-spacing: 0px;
  color: #278b97;
  text-transform: uppercase;
  opacity: 1;
}

.espacamento-subtitulo {
  padding: 0px 15px;
}

.icone-titulo {
  width: 38px;
  height: 38px;
}

.alerta {
  width: auto;
  margin: 10px 0px 40px 0px;
  height: 107px;
  background: #edefee 0% 0% no-repeat padding-box;
  border-radius: 20px;
  gap: 40px;
  align-items: center;
  display: flex;
}

.icone-alerta {
  width: 49px;
  height: 43px;
  margin-left: 50px;
}

.icone-solicitacoes {
  width: 15px;
  height: 19px;
  margin-right: 5px;
}

.text-alerta {
  padding-top: 15px;
  text-align: center;
  max-width: 75%;
}

.inicio-titulo {
  display: inline-flex;
  gap: 10px;
  align-items: end;
}

.espacamento-titulo {
  padding-bottom: 20px;
}

.espacamento-btn-adicionar {
  padding-right: 15px;
}

.espacamento-inicial {
  padding: 10px;
}

.novo-btn {
  text-align: left;
  font: normal normal 600 12px/15px Montserrat;
  letter-spacing: 0.77px;
  color: #ffffff;
  opacity: 1;
}

.v-card > .v-card__title > .v-icon {
  position: absolute;
  right: 24px;
  color: #ffffff;
}

.espacamento {
  padding: 20px 20px 0px 20px;
}

.botao-sair {
  background-color: transparent !important;
  color: #278b97 !important;
  border-radius: 15px !important;
  text-transform: initial !important;
  height: 40px !important;
  font-family: Montserrat;
  font-weight: 500 !important;
  box-shadow: none !important;
  width: 200px;
  border: 1px solid #278b97;
}
</style>
