<template>
  <v-container>
    <!-- Alerta -->
    <div class="alerta">
      <v-row>
        <v-col cols="12" md="2" xl="1" class="alerta-icone">
          <v-img
            src="@/assets/images/icone/espaco-colaborador/icone_sinal_de_aviso.svg"
          ></v-img>
        </v-col>
        <v-col cols="12" md="10" xl="11" class="alerta-texto">
          <span>
            Para saber os valores da mensalidade e outras informações sobre
            coparticipação, reembolso e carência, acesse
            <a
              href="https://nossahepta.com.br/IntranetHepta/#/detalhe-academia?id=302&pesquisa=false&artigo=true"
              target="_blank"
            >
              Nossa Hepta > Espaço do Colaborador > Benefícios > Plano de
              Odontológico</a
            >
          </span>
        </v-col>
      </v-row>
    </div>

    <!-- Titular -->
    <v-row>
      <v-col class="titulo-e-divider">
        <h1>Titular</h1>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="4" xl="3" offset-lg="4" offset-xl="6">
        <v-btn
          v-if="beneficio.length === 0"
          @click="abrirModalPlanoDental(false)"
          class="botao-principal w-100"
        >
          <v-icon class="mr-2">fas fa-plus</v-icon> Solicitar Plano Dental
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="3">
        <v-btn
          @click="showMinhasSolicitacoes = true"
          class="botao-principal w-100"
        >
          <v-img
            class="icone-solicitacoes"
            src="@/assets/images/icone/espaco-colaborador/icone_document.svg"
          ></v-img>
          Minhas Solicitações
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headerTitular"
          :items="beneficio"
          :hide-default-footer="true"
          no-data-text="Você não possui plano dental cadastrado!"
        >
          <template
            v-for="header in headerTitular.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>

          <template v-slot:[`item.requerimento`]="{ item }">
            <v-row>
              <v-col cols="12" md="6">
                <v-btn
                  @click="abrirModalPlanoDental(true, item)"
                  class="botao-amarelo"
                >
                  Trocar Plano Dental
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn @click="cancelarPlano(item)" class="botao-vermelho"
                  >Cancelar Benefícios</v-btn
                >
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Dependentes -->
    <v-row>
      <v-col class="titulo-e-divider">
        <h1>Dependentes</h1>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
        offset-md="6"
        offset-lg="8"
        offset-xl="9"
      >
        <v-btn
          v-show="beneficio.length > 0"
          @click="adicionarDependete()"
          class="botao-principal w-100"
        >
          <v-icon class="mr-2">fas fa-circle-plus</v-icon> Adicionar Dependentes
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headerDependente"
          :items="dependente"
          no-data-text="Você não possui dependentes com Planos dental cadastrados!"
          :hide-default-footer="true"
        >
          <template
            v-for="header in headerDependente.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>

          <template v-slot:[`item.requerimento`]="{ item }">
            <v-btn @click="removerDependente(item)" class="botao-vermelho"
              >Remover Dependente</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
    />

    <ModalMinhasSolicitacoes v-model="showMinhasSolicitacoes" />

    <ModalCancelarBeneficio
      v-model="modalCancelarBeneficio.showCancelarPlano"
      :beneficio="modalCancelarBeneficio.beneficio"
      :tipoMudanca="modalCancelarBeneficio.tipoMudanca"
      :tituloModal="modalCancelarBeneficio.tituloModal"
      :dependentes="modalCancelarBeneficio.dependente"
      @sucesso="
        mensagemSucesso(
          'Pedido realizado com sucesso. Aguarde o atendimento da solicitação.'
        )
      "
    />

    <ModalAdicionarDependentes
      v-model="modalAdicionarDependente.show"
      :tituloModal="modalAdicionarDependente.titulo"
      :dependentes="modalAdicionarDependente.dependente"
      :beneficio="modalAdicionarDependente.beneficio"
      @sucesso="abrirModalAnexarDocumento"
    />

    <ModalSolicitarPlano v-model="modalSolicitarPlanoDental.show"
    :titulo="modalSolicitarPlanoDental.titulo" :tipo="2"
    :alteracaoPlano="modalSolicitarPlanoDental.alteracaoPlano"
    :beneficioAnterior="modalSolicitarPlanoDental.beneficioAnterior""
    @create="abrirModalAnexarDocumento" />

    <ModalAnexarDocumentos
      v-model="modalAnexar.show"
      :pedido="modalAnexar.pedido"
      :alteracao="modalAnexar.alteracao"
    />
  </v-container>
</template>

<script>
import alerta from "@/components/Alerta.vue";
import modalMixin from "@/mixins/modalMixin";
import ModalMinhasSolicitacoes from "@/components/EspacoDoColaborador/Beneficios/modais/ModalMinhasSolicitacoes.vue";
import ModalCancelarBeneficio from "@/components/EspacoDoColaborador/Beneficios/modais/ModalCancelarBeneficio.vue";
import ModalAdicionarDependentes from "@/components/EspacoDoColaborador/Beneficios/modais/ModalAdicionarDependentes.vue";
import { TipoMudancaEnum } from "@/enums/beneficio/TipoMudancaEnum.js";
import ModalSolicitarPlano from "@/components/EspacoDoColaborador/Beneficios/modais/ModalSolicitarPlano.vue";
import ModalAnexarDocumentos from "@/components/EspacoDoColaborador/Beneficios/modais/ModalAnexarDocumentos.vue";

export default {
  components: {
    ModalMinhasSolicitacoes,
    ModalCancelarBeneficio,
    ModalAdicionarDependentes,
    alerta,
    ModalSolicitarPlano,
    ModalAnexarDocumentos,
  },
  props: {
    beneficio: Array,
    dependente: Array,
    headerTitular: Array,
    headerDependente: Array,
  },
  mixins: [modalMixin],
  data() {
    return {
      showMinhasSolicitacoes: false,
      modalCancelarBeneficio: {
        showCancelarPlano: false,
        beneficio: null,
        tipoMudanca: null,
        tituloModal: null,
        dependente: [],
      },
      modalAdicionarDependente: {
        show: false,
        dependentes: [],
        beneficio: null,
        titulo: "",
      },
      modalSolicitarPlanoDental: {
        show: false,
        beneficio: null,
        titulo: "",
        alteracaoPlano: false,
        beneficioAnterior: {},
      },
      modalAnexar: {
        show: false,
        pedido: null,
      },
    };
  },
  methods: {
    cancelarPlano(item) {
      const vm = this;
      vm.modalCancelarBeneficio = {
        beneficio: item,
        tipoMudanca: TipoMudancaEnum.CANCELAMENTO,
        tituloModal: "Cancelar plano dental",
        dependente: vm.dependente.filter((d) => {
          return d.beneficios == item.descricao;
        }),
        showCancelarPlano: true,
      };
    },
    removerDependente(item) {
      const vm = this;
      vm.modalCancelarBeneficio = {
        beneficio: vm.beneficio.find((b) => {
          return b.descricao == item.beneficios;
        }),
        tipoMudanca: TipoMudancaEnum.REMOVER_DEPENDENTE,
        tituloModal: `Remover ${item.nome} do plano dental`,
        dependente: [item],
        showCancelarPlano: true,
      };
    },
    adicionarDependete() {
      const vm = this;
      vm.modalAdicionarDependente = {
        titulo: "Adicionar dependente ao plano dental",
        dependente: vm.dependente,
        beneficio: vm.beneficio[0],
        show: true,
      };
    },
    abrirModalPlanoDental(alteracao, beneficioAnterior = {}) {
      const vm = this;
      vm.modalSolicitarPlanoDental = {
        titulo: !alteracao ? `Solicitar Plano Dental` : "Trocar Plano Dental",
        show: true,
        alteracaoPlano: alteracao,
        beneficioAnterior: beneficioAnterior,
      };
    },
    abrirModalAnexarDocumento(item) {
      console.log(item);
      const vm = this;
      vm.modalAnexar = {
        pedido: item,
        show: true,
        alteracao: item.tipoMudanca.id === 1,
      };
    },
  },
};
</script>

<style scoped></style>
