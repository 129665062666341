var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-dialog',{attrs:{"min-width":"500","max-width":"1500"},on:{"click:outside":function($event){return _vm.fecharModal()}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('h3',[_vm._v(_vm._s(_vm.titulo))]),_c('v-icon',{on:{"click":function($event){return _vm.fecharModal()}}},[_vm._v("fa-times")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Importante!")]),_c('v-divider',{staticClass:"mt-2"})],1),_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.listaTransporte),function(item,index){return _c('ul',{key:index,staticClass:"text-justify"},[_c('li',[_vm._v(_vm._s(item))])])}),0)],1),_c('v-row',[_c('v-col',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-left"},[_vm._v(" Selecione o tipo de alteração para o vale transporte: "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"Alteração de trajeto"},on:{"change":function($event){_vm.isReajusteValor = false}},model:{value:(_vm.isAlteracaoTrajeto),callback:function ($$v) {_vm.isAlteracaoTrajeto=$$v},expression:"isAlteracaoTrajeto"}})],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":"Reajuste no valor da passagem"},on:{"change":function($event){_vm.isAlteracaoTrajeto = false}},model:{value:(_vm.isReajusteValor),callback:function ($$v) {_vm.isReajusteValor=$$v},expression:"isReajusteValor"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Localidade","clearable":"","menu-props":{ bottom: true, offsetY: true },"outlined":"","hide-details":"auto","rules":[
                      (v) => !!v || 'Obrigatório informar a localidade',
                    ],"items":['DF', 'Entorno', 'Outros Estados']},on:{"change":function($event){_vm.vModel.localidade == 'Outros Estados' ||
                      !!_vm.vModel.localidade
                        ? (_vm.vModel.possuiCartao = null)
                        : null}},model:{value:(_vm.vModel.localidade),callback:function ($$v) {_vm.$set(_vm.vModel, "localidade", $$v)},expression:"vModel.localidade"}})],1)],1),_c('v-row',[(
                    _vm.vModel.localidade == 'DF' ||
                    _vm.vModel.localidade == 'Entorno'
                  )?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('p',{staticClass:"text-left"},[_vm._v(" Possui cartão DFTrans? "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('v-radio-group',{attrs:{"rules":[
                      (v) =>
                        _vm.vModel.localidade === 'Outros Estados' ||
                        !!v ||
                        'Obrigatório informar se possui cartão DFTrans',
                    ],"inline":""},on:{"change":function($event){!!_vm.vModel.possuiCartao ? (_vm.vModel.cartao = null) : null}},model:{value:(_vm.vModel.possuiCartao),callback:function ($$v) {_vm.$set(_vm.vModel, "possuiCartao", $$v)},expression:"vModel.possuiCartao"}},[_c('v-radio',{attrs:{"label":"Sim","value":"sim"}}),_c('v-radio',{attrs:{"label":"Não","value":"nao"}})],1)],1):_vm._e(),(_vm.vModel.possuiCartao === 'sim')?_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('p',{staticClass:"text-left"},[_vm._v(" Número do cartão DFTrans: "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('v-text-field',{attrs:{"label":"Número do cartão","outlined":"","clearable":"","hide-details":"auto","rules":[
                      (v) =>
                        _vm.vModel.localidade === 'Outros Estados' ||
                        _vm.vModel.possuiCartao === 'nao' ||
                        !!v ||
                        'Obrigatório informar número do cartão DFTrans',
                    ]},model:{value:(_vm.vModel.cartao),callback:function ($$v) {_vm.$set(_vm.vModel, "cartao", $$v)},expression:"vModel.cartao"}})],1):_vm._e()],1),(_vm.isAlteracaoTrajeto)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-left"},[_vm._v(" Comprovante de residência: "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('v-file-input',{attrs:{"label":"Anexe o comprovante de residência","hide-details":"auto","prepend-icon":"","prepend-inner-icon":"fas fa-paperclip","outlined":"","clearable":"","rules":[
                      (v) =>
                        !!v || 'Obrigatório anexar comprovante de residência',
                    ]},model:{value:(_vm.vModel.comprovanteResidencia),callback:function ($$v) {_vm.$set(_vm.vModel, "comprovanteResidencia", $$v)},expression:"vModel.comprovanteResidencia"}})],1)],1):(_vm.isReajusteValor)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-left"},[_vm._v(" Informativo sobre reajuste: "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('v-file-input',{attrs:{"label":"Anexe o informe sobre o reajuste da passagem","hide-details":"auto","prepend-icon":"","prepend-inner-icon":"fas fa-paperclip","outlined":"","clearable":"","rules":[
                      (v) =>
                        !!v ||
                        'Obrigatório anexar informativo sobre reajuste',
                    ]},model:{value:(_vm.vModel.informativoReajuste),callback:function ($$v) {_vm.$set(_vm.vModel, "informativoReajuste", $$v)},expression:"vModel.informativoReajuste"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("Informações do Trajeto")]),_c('v-form',{ref:"formTrajeto",attrs:{"lazy-validation":""}},[_c('v-row',{staticClass:"row-trajeto"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_vm._v(" Trajeto: "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_c('v-text-field',{attrs:{"outlined":"","clearable":"","hide-details":"auto","rules":[
                            (v) => !!v || 'Obrigatório informar o trajeto',
                          ]},model:{value:(_vm.vModel.trajeto),callback:function ($$v) {_vm.$set(_vm.vModel, "trajeto", $$v)},expression:"vModel.trajeto"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_vm._v(" Linha e Empresa: "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_c('v-text-field',{attrs:{"outlined":"","clearable":"","hide-details":"auto","rules":[
                            (v) =>
                              !!v || 'Obrigatório informar a linha e empresa',
                          ]},model:{value:(_vm.vModel.linha),callback:function ($$v) {_vm.$set(_vm.vModel, "linha", $$v)},expression:"vModel.linha"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_vm._v(" Valor: "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.vModel.valor ? _vm.money : null),expression:"vModel.valor ? money : null"}],attrs:{"outlined":"","clearable":"","hide-details":"auto","rules":[
                            (v) => !!v || 'Obrigatório informar o valor',
                          ]},model:{value:(_vm.vModel.valor),callback:function ($$v) {_vm.$set(_vm.vModel, "valor", $$v)},expression:"vModel.valor"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4","offset-lg":"8"}},[_c('v-btn',{staticClass:"botao-outlined-azul w-100",on:{"click":function($event){return _vm.adicionarTrajeto()}}},[_vm._v("Adicionar Trajeto")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{style:(_vm.showMensagemTrajeto
                              ? 'border: 2px solid; border-color: #ff5252;border-radius: 15px 15px 0 0;'
                              : null),attrs:{"headers":_vm.headerTable,"items":_vm.trajetos,"hide-default-footer":true,"no-data-text":"Nenhum trajeto informado!"},scopedSlots:_vm._u([_vm._l((_vm.headerTable.filter((header) =>
                              header.hasOwnProperty('formatter')
                            )),function(header){return {key:`item.${header.value}`,fn:function({ value }){return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:`item.acao`,fn:function(data){return [_c('v-btn',{attrs:{"id":"btn-excluir"},on:{"click":function($event){return _vm.trajetos.splice(data.index, 1)}}},[_vm._v("X")])]}}],null,true)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showMensagemTrajeto),expression:"!showMensagemTrajeto"}],attrs:{"id":"mensagemTrajeto"}},[_vm._v(" Obrigatório informar os dados do trajeto ")])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"my-2"})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"botao-secundario-par mr-2 largura-btn",on:{"click":function($event){return _vm.fecharModal()}}},[_vm._v("Fechar ")]),_c('v-btn',{staticClass:"botao-principal mr-2 largura-btn",attrs:{"disabled":!_vm.isAlteracaoTrajeto && !_vm.isReajusteValor},on:{"click":function($event){return _vm.solicitar()}}},[_vm._v("Solicitar ")])],1)],1),_c('alerta',{attrs:{"exibir":_vm.modal.exibir,"titulo":_vm.modal.titulo,"mensagem":_vm.modal.mensagem},on:{"fecharModal":_vm.fecharModal}}),_c('loader',{attrs:{"loader-primario":_vm.loader}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }